export default {
    setModalShow(state, payload){
        state.isModalShow =payload;
    },
    setEnterPrice(state, payload) {
        state.isEnterPrice = payload;
    },
    setLoading(state, payload) {
        state.isLoading = payload;
    }
}