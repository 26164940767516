export default {
    "information": "Получите более подробную информацию",
    "application": "Оставьте заявку и мы с вами свяжемся",
    "name": "ФИО",
    "phone": "Телефон",
    "agreement": "Я соглашаюсь на сбор и обработку моих персональных данных",
    "sendOrder": "Отправить заявку",
    "orWith" :"Или с помощью",
    "enterPhoneNumber": "Введите номер телефона",
    "agreeError": "Согласитесь с политикой сервиса",
    "numberNotValid": "Номер не валидный",
    "thankYou": "Спасибо",
    "requestSend": "Ваш запрос отправлен. Мы получили ваши данные и свяжемся с вами в ближайшее время, чтобы обсудить все детали.",
    "requestFailed": "Ваш запрос не был отправлен",
    "enterName": "Введите ФИО",
}