import { createApp } from "vue";
import App from "./App.vue";
import i18n from "./i18n";
import store from "./store";
import router from "./routes";

const app = createApp(App);

app.use(i18n)
  .provide("$i18n", i18n)
  .use(store)
  .provide("$store", store)
  .use(router)

  

app.mount("#app");
