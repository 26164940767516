import { createI18n } from 'vue-i18n';
import enUS from './enUs';
import ru from './ru';
import kz from "./kz"



const locale = localStorage.getItem('lang') || 'en-us';

const i18n = createI18n({
    locale,
    messages: {
        'en-us': enUS,
        ru: ru, 
        kz: kz,
    }
});

export default i18n;