export const routes = [

    {
    path: "/",
    name: "nullPage",
    component: () => import('@/pages/landing/NullPage.vue')
    },
    {
    path: "/aboutUs",
    name: "AboutUsPage",
    component: () => import('@/pages/aboutUs/AboutUsPage.vue')   
    }
    ]