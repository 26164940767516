export default {
    "information": "Get more information",
    "application": "Leave a request and we will contact you",
    "name": "Full name",
    "phone": "Phone number",
    "agreement": "I agree to the collection and processing of my personal data",
    "sendOrder": "Send request",
    "orWith": "Or using",
    "enterPhoneNumber": "Enter your phone number",
    "agreeError": "You must agree to the terms of service",
    "thankYou": "Thank you",
    "requestSend": "Your request has been sent. We have received your information and will contact you shortly to discuss the details.",
    "requestFailed": "Request failed",
    "numberNotValid": "Invalid number",
    "enterName": "Enter your Name",
  }