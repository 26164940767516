export default {
    "businessSolution": "We create ecosystemic IT solutions for businesses",
    "efficiency": "Digitalization of business processes to improve efficiency and transparency in your company",
    "takeSolution": "Choose a solution",
    "optimization": "TrustMe - IT solution for business optimization",
    "optimizationTitle1": "Best Startup",
    "optimizationSubTitle1": "according to Hero Training USA",
    "optimizationTitle2": "Blockchain Startup Awards",
    "optimizationSubTitle2": "award in Silicon Valley",
    "optimizationTitle3": "TOP-10 startups",
    "optimizationSubTitle3": "Central Asia Digital Bridge 2023",
    "optimizationTitle4": "Among the best startups of Central Asia",
    "optimizationSubTitle4": "according to Google For Startups",
    "ourProducts": "Our products",
    "productButtonGo": "Go",
    "productButtonDisabled": "Coming soon",
    "individualSolution": "Individual TrustMe solutions for business",
    "productButtonDemo": "Request a demo",
    "productDescription1": "TrustContract - a service for instant and secure signing of contracts via SMS, using smart contracts and convenient templates for maximum productivity.",
    "productDescription2": "We will configure your document workflow and adapt TrustMe functions to your business requirements, ensuring cost reduction, data security, and ease of use of the electronic document management system.",
    "productDescription3": "A service for automation and digitalization of primary accounting documents, with optimization of creation, signing and storage of documents.",
    "productDescription4": "A service for automating HR processes, simplifying the submission and approval of applications, and increasing the efficiency of the HR department.",
    "customerOpinions": "What customers say",
    "ourVision": "Our vision",
    "visionLabel1": "Mission",
    "visionDescription1": "To make the world a better place through technology and innovation",
    "visionLabel2": "Goal",
    "visionDescription2": "To become the first unicorn in Central Asia",
    "visionLabel3": "Values",
    "visionDescription3": "Transparency, trust and openness in all aspects of our activities",
    "team": "Team",
    "teamDescription": "Our work is based on transparency, trust and openness in all aspects of our activities. Join us!",
    "seeVacancy": "See vacancies",
    "tag1": "efficiency",
    "tag2": "performance",
    "tag3": "customer focus",
    "tag4": "professionalism",
    "tag5": "efficiency",
    "tag6": "performance",
    "tag7": "customer focus",
    "tag8": "professionalism",
    "news": "News",
    "newsLabel1": "White Paper for Stage I Seed Investment",
    "newsDescription1": "Hello, friends! Today I would like to tell you about the man who made the impossible possible - Chingiz Dauletbayev, the founder and CEO of TrustMe.",
    "newsLabel2": "Trust Contract 2024 Updates",
    "newsDescription2": "Dear friends, we have prepared a few cool updates to the TrustContract service that you will definitely love. In this article we will try to describe them.",
    "newsLabel3": "TOP-5 frequently asked questions about Trust Contract",
    "newsDescription3": "In this article we have prepared for you the TOP-5 frequently asked questions of users about TrustContract.",
    "seeNews": "See more",
    "trustTechnologies": "Trusted Technologies",
    "technologiesDescription": "Our technologies are created by professionals for professionals with maximum care for customers and their needs.",
    "contactUs": "Contact us",
    "time":"{time} minutes",
  }