export default {
    "information": "Толық ақпарат алу",
    "application": "Өтінім қалдырыңыз және біз сізбен байланысқа аламыз",
    "name": "ТАӘ",
    "phone": "Телефон",
    "agreement": "Менің жеке деректерімді жинауға және өңдеуге келісемін",
    "sendOrder": "Өтінім жіберу",
    "orWith": "Мұндай амалдармен де",
    "enterPhoneNumber": "Телефон нөмерін енгізіңіз",
    "agreeError": "Сервис саясатына келісіңіз керек",
    "thankYou": "Рақмет",
    "requestSend": "Сіздің сұрауыңыз жіберілді. Біз сіздің деректеріңізді алдық және жақын арада сізбен байланысып, барлық мәселелерді талқылаймыз.",
    "requestFailed": "Сұрауыңыз жіберілмеді",
    "numberNotValid": "Нөмер жарамсыз",
    "enterName":"Аты-жөнін енгізіңіз",
  }