<template>
   <Header/>
   <RouterView />
   <Footer/>
   <Spinner/>
   <ContactUs v-if="isModalShow" @close-modal="modalHide" />
</template>

<script>
import { mapGetters } from "vuex";
import {defineComponent} from "vue";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import ContactUs from '@/components/ContactUs.vue';
import '../public/style.scss'
import { RouterView } from "vue-router";
import Spinner from "@/components/Spinner.vue";
export default defineComponent({
  components: {Header, Footer, ContactUs, RouterView, Spinner},
  computed: {
    ...mapGetters("main", {
      isModalShow: "getShowModal",
    }),
  },
  methods: {
    modalHide() {
      setTimeout(() => {
        this.$store.commit("main/setModalShow", false);
      }, 300);
    },
  },
})
</script>

<style>
#app {
  font-family: Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #EDF2F4;

}
</style>
