<template>
  <header>
    <div class="header_container">
      <div class="header_logo">
        <img src="/assets/icons/Logo.svg" alt="" />
      </div>
      <ul class="nav" :class="{ 'show-menu': isMenuVisible }">
        <li class="nav-item">
          <a
            style="border: none"
            class="nav-link text-black break-on-mobile text-center"
            @click="scrollToSection"
            >{{ $t('header.products') }}</a
          >
        </li>
        <li class="nav-item">
          <a
            href="https://blog.trustme.kz/career/"
            
            class="nav-link text-black break-on-mobile"
            >{{ $t("header.career") }}</a
          >
        </li>
        <li class="nav-item">
          <a
            href="https://blog.trustme.kz/news/"
            
            class="nav-link text-black break-on-mobile"
            >{{ $t("header.news") }}</a
          >
        </li>
        <li class="nav-item">
          <router-link
            @click="toggleBurgerIfMobile"
            to="aboutUs"
            class="nav-link text-black break-on-mobile"
            >{{ $t('header.aboutUs') }}</router-link
          >
          <!-- <a href="https://blog.trustme.kz/news/" class="nav-link text-black break-on-mobile">{{ $t('header.aboutUs') }}</a> -->
        </li>
        <li class="nav-item mobile">
          <select
            id="languageSelect"
            v-model="selectedCityValue"
            @change="changeLanguage()"
            class="ml-5"
          >
            <option value="ru">RU</option>
            <option value="kz">KZ</option>
            <option value="en-us">EN</option>
          </select>
        </li>
        <li class="nav-item mobile btn primary" @click="modalShow">{{ $t('header.contactUs') }}</li>
      </ul>
      <div
        class="d-flex align-items-center justify-content-around"
        style="gap: 24px"
      >
        <select
          id="languageSelect"
          v-model="selectedCityValue"
          @change="changeLanguage()"
          class="ml-5"
        >
          <option value="ru">RU</option>
          <option value="kz">KZ</option>
          <option value="en-us">EN</option>
        </select>
        <button class="btn primary align-items-center" @click="modalShow">
          {{ $t('header.contactUs') }}
        </button>
        <div
          :class="['nav__burger', { active: isActive }]"
          @click="toggleBurger"
        >
          <span></span>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "HeaderComponent",
  props: {
    message: String,
  },
  components: {},
  data() {
    return {
      selectedCityValue: this.getDefaultLanguage(),
      isActive: false,
      isMenuVisible: false,
    };
  },
  methods: {
    toggleBurgerIfMobile() {
      if (window.innerWidth <= 993) {
        this.toggleBurger();
      }
  },
    changeLanguage() {
      localStorage.setItem("lang", this.selectedCityValue);
      this.$i18n.locale = this.selectedCityValue;
    },
    scrollToSection() {
      const section = document.getElementById("target-section");
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
      else{
        this.$router.push('/');
      }
      this.toggleBurgerIfMobile();
      
    },
    getDefaultLanguage() {
      const savedLang = localStorage.getItem("lang");
      return savedLang ? savedLang : "ru";
    },
    toggleBurger() {
      this.isActive = !this.isActive;
      this.isMenuVisible = !this.isMenuVisible;
    },
    modalShow() {
      this.$store.commit("main/setModalShow", true);
    },
  },
  created() {
    this.$i18n.locale = this.selectedCityValue;
  },
};
</script>

<style scoped lang="scss">
header {
  position: sticky;
  z-index: 2;
  top: 0;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #edf2f4;
  height: 84px;
  width: 100%;
  box-sizing: border-box;
  &::after {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background-color: #dddddd;
    position: absolute;
    bottom: 0px;
    left: 0;
  }
}

.header_container {
  max-width: 1300px;
  width: 100%;
  padding: 20px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  gap: 32px;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
}

.text-black {
  color: #203939;
  background: #edf2f4;
}

select {
  background: none;
  color: #203939;
  border: none;
  padding: 10px;
}

.nav {
  display: flex;
  list-style: none;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  li {
    padding: 10px;
    text-align: center;
    cursor: pointer;
  }
  .mobile {
      display: none;
    }

  &.show-menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 84px; 
    left: 0;
    padding: 10px 0px;
    width: 100%;
    background: #edf2f4;
    z-index: 999;
    li {
      display: block;
    }
    li.mobile{
        display: none;
      }
  }
}

.nav__burger {
  position: relative;
  display: none;
  flex-wrap: wrap;
  align-content: center;
  justify-content: flex-end;
  width: 30px;
  z-index: 50;
  height: 18px;

  span {
    height: 2px;
    width: 80%;
    transform: scale(1);
    background-color: black;
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    height: 2px;
    width: 80%;
    background: black;
    transition: all 0.3s ease 0s;
  }

  &::before {
    top: 0;
  }

  &::after {
    bottom: 0;
  }
}

.nav__burger.active {
  span {
    transform: scale(0);
  }

  &::before {
    top: 50%;
    transform: rotate(-45deg) translate(0, -50%);
  }

  &::after {
    bottom: 50%;
    transform: rotate(45deg) translate(0, 50%);
  }
}

@media only screen and (max-width: 992px) {
  .break-on-mobile::after {
    content: "";
    display: block;
  }
  li {
    display: none;
  }
  .nav__burger {
    display: flex;
  }
}

@media only screen and (max-width: 768px) {
  header {
    li {
      display: none;
    }

    div {
      img {
        width: 100%;
      }
    }
  }
  .nav {
    display: none;
  }
}

@media only screen and (max-width: 500px) {
  .header_logo {
    display: block;
  }
  .d-flex.align-items-center.justify-content-around > select,
  .d-flex.align-items-center.justify-content-around > button {
    display: none;
  }
  .nav.show-menu{
    li.mobile {
    display: block;
  }
    li.mobile:last-of-type {
      margin-bottom: 10px;
    }
  }
  
}

.gap-15 {
  gap: 15px;
}
</style>
