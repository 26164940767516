<template>
  <div class="contact_modal">
    <div class="contact_modal__wrapper">
      <div class="contact_modal__close">
        <button
          class="contact_modal__close_button"
          @click="$emit('close-modal')"
        >
          <span class="fs-5 bi bi-x"></span>
        </button>
      </div>
      <div class="contact_modal__success" v-if="isDone">
        <div class="modal_success__img">
          <img src="/assets/icons/thankYou.svg" ondragstart="return false;"/>
        </div>
        <div class="modal_success__text">
          <h4 class="text-title">{{ $t('contactUs.thankYou') }}</h4>
          <p class="body4 text-description">
            {{ $t('contactUs.requestSend') }}
          </p>
        </div>
      </div>
      <div class="contact_modal__block" v-else>
        <div class="contact_modal__title">
          <h4 class="text-center">{{ $t("contactUs.information") }}</h4>
          <p class="body3 text-description text-center">
            {{ $t("contactUs.application") }}
          </p>
        </div>
        <div class="contact_modal__form">
          <div class="input__name">
            <p>{{ $t("contactUs.name") }}</p>
            <input
              v-model="form.name"
              class="form_input"
              :placeholder="$t('contactUs.enterName')"
            />
          </div>
          <div class="input_phone">
            <p>{{ $t("contactUs.phone") }}</p>
            <VuePhone
              :phone="form.phoneNumber"
              @updatePhone="handlePhone"
              class="contact-modal__vue-tel-input"
              @isValid="setValidityOfPhoneNumber"
            />
          </div>
          <div class="form-additional">
            <div class="form_confidential">
              <MCheckbox
                v-model="isAgree"
                :label="$t('contactUs.agreement')"
              ></MCheckbox>
              <p class="body4" v-if="validationError.message">{{ validationError.message }}</p>
            </div>
            <button class="btn primary text-center" @click="sendOrder">
              {{ $t("contactUs.sendOrder") }}
            </button>
          </div>
        </div>
        <div class="contact_additional_content">
          <div class="contact-modal-or">
            <div class="contact-modal-divider"></div>
            <div>
              <p>{{ $t("contactUs.orWith") }}</p>
            </div>
            <div class="contact-modal-divider"></div>
          </div>
          <div class="contract-modal__social-media">
            <a
              href="https://wa.me/77074957707"
              
              class="whatsApp-link"
              ><img src="/assets/icons/color-whatsapp.svg" ondragstart="return false;"/>
              <p>WhatsApp</p></a
            >
            <a
              href="https://www.instagram.com/trustme.kz/"
              
              class="instagram-link"
              ><img src="/assets/icons/color-instagram.svg" ondragstart="return false;"/>
              <p>Instagram</p></a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MCheckbox from "@/components/MCheckbox";
import VuePhone from "./VuePhone.vue";
import { mapGetters } from "vuex";
export default {
  name: "ContactUs",
  components: { MCheckbox, VuePhone },
  data() {
    return {
      isAgree: null,
      isDone: false,
      validationError: {
        message: "",
      },
      form: {
        name: "",
        phone: "",
        isPhoneValid: false,
      },
    };
  },
  computed: {
    ...mapGetters("main",{
      isEnterPrice: "getEnterPrice",
    })
  },
  methods: {
    handlePhone(phone) {
      this.form.phoneNumber = phone;
    },
    setValidityOfPhoneNumber(isValid) {
      this.form.isPhoneValid = isValid;
    },
    jsonKeysToUpper(json) {
      // kr: Costyl to fix camelCase JSON returned from server
      // in opposite to Bitrix upper case JSON
      const result = {};
      const has = Object.prototype.hasOwnProperty;

      for (const key in json) {
        if (!has.call(json, key)) continue;
        result[key.toUpperCase()] = json[key];
      }

      return result;
    },
    async sendCallback() {
      const regex = /^[0-9]+$/;
      if (
        regex.test(this.form.phoneNumber) &&
        this.form.phoneNumber.length >= 10
      ) {
        try {
          const bitrixProxyUrl =
            "https://test.trustme.kz/" + "common_temp_service/";
          console.log(bitrixProxyUrl);
          const country = "kz";

          const request = await fetch(
            bitrixProxyUrl +
              "GetContactListBitrixProxy/" +
              country +
              "?phone=" +
              this.form.phoneNumber
          );
          const data = await request.json();
          const [firstContact] = data.result;
          console.log(firstContact);
          console.log(data.result);
          console.log(Array.isArray(data.result));
          let contactId = null;
          if (Array.isArray(data.result)) {
            if (firstContact) {
              contactId = this.jsonKeysToUpper(firstContact).ID;
            } else {
              const request = await fetch(
                bitrixProxyUrl + "AddContactBitrixProxy",
                {
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                  },
                  method: "POST",
                  body: JSON.stringify({
                    country,
                    fields: {
                      NAME: this.form.name,
                      PHONE: [
                        { VALUE: this.form.phoneNumber, VALUE_TYPE: "WORK" },
                      ],
                      SOURCE_ID: "UC_G3WHC3",
                    },
                  }),
                }
              );
              const data = await request.json();
              contactId = data.result;
            }
            const title = this.isEnterPrice ? "Enterprise - крупный заказчик" : "TrustContract - заявки с контрактов";
            const body = {
              country,
              fields: {
                TITLE: title,
                TYPE_ID: "GOODS",
                STAGE_ID: "NEW",
                CONTACT_ID: contactId,
                SOURCE_ID: "UC_G3WHC3",
              },
            };

            fetch(bitrixProxyUrl + "AddDealBitrixProxy", {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              method: "POST",
              body: JSON.stringify(body),
            });

            this.form.phoneNumber = "";
            this.form.name = "";
          }
        } catch (err) {
          console.error(err.message);
          this.validationError.message = this.$t('contactUs.requestFailed');
        } finally {
          this.isDone = true;
          // this.closePopUp();
        }
      } else {
        this.orderPhoneError = true;
      }
    },
    validateFields() {
      const phoneRegex = /^\+?[0-9]+(?:[ -][0-9]+)*$/;
      if (!phoneRegex.test(this.form.phoneNumber) || !this.form.isPhoneValid) {
        this.validationError.message = this.$t(
          "contactUs.numberNotValid"
        );
      }
      if (!this.form.name) {
        this.validationError.message = this.$t('contactUs.enterName');
      }
      if (!this.isAgree) {
        this.validationError.message = this.$t("contactUs.agreeError");
      }

      if (this.validationError.message) {
        console.log(this.validationError.message);
        return false;
      }
      return true;
    },
    sendOrder() {
      if (this.validateFields()) {
        this.sendCallback();
      }
    },
    closePopUp() {
      this.form = {
        name: "",
        phoneNumber: "",
        isPhoneValid: false,
        company: "",
      };
      this.$emit("close-modal");
    },
  },
};
</script>

<style scoped>
.contact_modal {
  min-height: 100vh;
  min-width: 100%;
  background: rgba(0, 0, 0, 0.315);
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  z-index: 2;
}

.form_input {
  padding: 6px 16px;
  align-content: center;
  display: flex;
  border-radius: 6px;
  height: 48px;
  border: 1px solid var(----, #e6eaed);
  background: var(---, #fafcfc);
  width: 100%;
}
.contact_modal__wrapper {
  width: 768px;
  display: flex;
  padding: 32px 24px;
  flex-direction: column;
  gap: 32px;
  align-items: center;
  background: white;
  border-radius: 12px;
  transform: translateY(100%);
  animation: slideUp 0.3s forwards;
}
.contact_modal__success{
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 48px 0px;
  max-width: 460px;
  align-content: center;
}
.modal_success__img {
  text-align: center;
}
.modal_success__text{
  display: flex;
  flex-direction: column;
  gap:12px;
  text-align: center;
}
.contact_modal__block{
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.contact_modal--hide .contact_modal__wrapper {
  animation: slideDown 0.3s forwards; /* Slide down animation */
}
.contact_modal__close {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  align-self: flex-end;
}
.contact_modal__close_button {
  border: none;
  background: none;
}
.contact_modal__content {
  display: flex;
  flex-direction: column;
}
.contact_modal__title {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}
.contact_modal__form {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.contact_additional_form {
  background: #fdfdfd;
  display: flex;
  flex-direction: column;
}
.form_confidential {
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-content: center;
}
.form_checkbox {
  width: 24px;
  height: 24px;
  border-radius: 6px;
  border: 1.5px solid #1d1d1f24;
}
.form-additional {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 20px;
}
.contact-modal-or {
  display: flex;
  align-content: center;
  align-items: center;
  gap: 32px;
}
.contact-modal-divider {
  height: 1px;
  background: #e6eaed;
  flex: 1;
}
.contract-modal__social-media {
  display: flex;
  justify-content: center;
  gap: 12px;
  background: #ffffff;
  align-self: stretch;
}
a {
  padding: 12px 48px;
  display: flex;
  gap: 12px;
  align-content: center;
}
p {
  margin: 0px;
}
.whatsApp-link {
  color: #08994b;
}
.instagram-link {
  color: #d73f8f;
}

@media only screen and (max-width: 767px) {
  .contact_modal__wrapper {
    max-width: 375px;
    width: 375px;
  }
  a {
    padding: 20px;
  }
}
@keyframes slideUp {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes slideDown {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100%);
  }
}
</style>