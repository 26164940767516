<template>
    <div class="spinner-overlay" v-if="isLoading">
        <div class="spinner"></div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
    name: 'SpinnerVue',
    computed: {
    ...mapGetters("main", {
      isLoading: "getLoading",
    }),
  },
}
</script>

<style lang="scss">
@import '../../public/variables.scss';
.spinner-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    .spinner {
        border: 6px solid rgba(0,0,0, 0.1);
        border-left: 6px solid $primary;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        animation:  spin 1s linear infinite;
    }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
</style>