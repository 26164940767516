import { createStore } from 'vuex';
import main from './main';

const store = createStore({
    modules: {
      main
    },
    strict: process.env.DEBUGGING,
  });


export default store;